import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import CoursesCard from 'components/CoursesCard';
import Seo from 'components/seo';

import * as S from '../components/StyledCareer/styled';

const courses = ({ data }) => {
  const { edges } = data.allMarkdownRemark;

  return (
    <Layout>
      <Seo title="Melhores cursos do mercado" />
      <S.CourseWrapper>
        <S.CourseCategoryCard>
          <S.CourseCategoryTexts>
            <S.CourseCategory>
              Cursos que a DevLoad é afiliada!!!
            </S.CourseCategory>
            <S.CourseCategoryDescription>
              Cursos selecionados para te preparar para o mercado e alavancar sua carreira no mundo do desenvolvimento.
            </S.CourseCategoryDescription>
          </S.CourseCategoryTexts>
        </S.CourseCategoryCard>
        <S.CourseContent>
          {edges.map(item => (
            <CoursesCard
              key={item.node.frontmatter.slug}
              title={item.node.frontmatter.title}
              slug={item.node.frontmatter.slug}
              image={item.node.frontmatter.image.childImageSharp.fluid}
              introduction={item.node.frontmatter.introduction}
              author={item.node.frontmatter.author}
              class_course={item.node.frontmatter.class_course}
              level={item.node.frontmatter.level}
            />
          ))}
        </S.CourseContent>
      </S.CourseWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [fields___source], order: ASC }
    ) {
      totalCount
      edges {
        node {
          excerpt
          frontmatter {
            title
            slug
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            introduction
            author
            class_course
            price {
              price_part
              number_part
            }
          }
        }
      }
    }
  }
`;

export default courses;
