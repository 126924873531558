import styled from 'styled-components';
import media from "styled-media-query";
import CodeImg from '../../images/courses/web.jpg';
 
export const CourseWrapper = styled.section`
    width: 100%;
    padding: 0;   
    margin: 0 auto;
    text-align: left;

    ${media.lessThan("medium")`
        width: auto;
   `}
`

export const CourseCategoryCard = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 80px 0 150px;
    align-items: center;
    justify-content: left;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${CodeImg});

    ${media.lessThan("medium")`
        padding: 50px 0;
        background-position: center;
        background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${CodeImg});
    `}
`

export const CourseCategoryTexts = styled.div`
    width: 100%;
    max-width: 1150px;
    margin: auto;
    padding: 0 20px;
`

export const CourseCategory = styled.h1`
    width: 100%;
    max-width: 600px;
    line-height: 1em;
    font-weight: 700;
    font-size: 2.8rem;
    margin: 0;
    background: -webkit-linear-gradient(#1cffb3, #5996f0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    ${media.lessThan("medium")`
        font-size: 2.2rem;
        line-height: 42px;
    `}
`

export const CourseCategoryTitle = styled.h2`
    line-height: 1em;
    font-weight: 700;
    margin: 5px 0 0;
    background: -webkit-linear-gradient(#1cffb3, #5996f0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
    ${media.lessThan("medium")`
        font-size: 1.6rem;
    `}
`

export const CourseCategoryDescription = styled.p`
    width: 100%;
    max-width: 600px;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 20px 0 0;

    ${media.lessThan("medium")`
        width: 100%;
        font-size: 1rem;
    `}
`

export const CourseContent = styled.div`
    width: 100%;
    max-width: 1150px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 0 auto;
    padding: 0 20px;
    gap: 20px;

    ${media.lessThan("medium")`
        grid-template-columns: 1fr;
        padding: 0 20px;
    `}
`

export const CourseCategorySubTitle = styled.h2`
    font-size: 1.2rem;
    line-height: 1em;
    font-weight: 700;
    margin: 40px 0 50px;
    color: var(--color-text);
    letter-spacing: 0px;

    ${media.lessThan("medium")`
        font-size: 1rem;
    `}
`
