import styled from 'styled-components';
import media from "styled-media-query";
import { Link } from 'gatsby';
 
export const CourseCardWrapper = styled(Link)`
    display: block;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    background: #1d1d1f;
    box-shadow: 0 0 0.4rem #141415;
    border-radius: 8px;
    padding: 15px 15px 80px;
    text-align: left;
    margin-top: -60px;

    &::before,
    &::after {
      inset: 0px;
      content: "";
      position: absolute;
      transition: transform 0.3s ease 0s;
      border-radius: 8px;
    }

    &::before,
    &::after {
      inset: 0px;
      content: "";
      position: absolute;
      transition: transform 0.3s ease 0s;
    }

    &::before {
      border-top: 2px solid var(--color-primary-light);
      border-bottom: 2px solid var(--color-primary-light);
      transform: scale3d(0, 1, 1);
    }

    &::after {
      border-left: 2px solid var(--color-primary-light);
      border-right: 2px solid var(--color-primary-light);
      transform: scale3d(1, 0, 1);
    }

    &:hover::before,
    &:hover::after {
      transform: scale3d(1, 1, 1);
      transition: transform 0.5s;
    }

    ${media.lessThan("medium")`
      margin-top: 20px;
      padding: 20px 20px 80px;

      &::before,
      &::after {
        inset: 0px;
        content: "";
        position: absolute;
        transition: transform 0.3s ease 0s;
        border-radius: 8px;
      }

      &::before,
      &::after {
        inset: 0px;
        content: "";
        position: absolute;
        transition: transform 0.3s ease 0s;
      }

      &::before {
        border-top: none;
        border-bottom: none;
        transform: scale3d(0, 0, 0);
      }

      &::after {
        border-left: none;
        border-right: none;
        transform: scale3d(0, 0, 0);
      }
      &:hover::before,
      &:hover::after {
        transform: scale3d(0, 0, 0);
        transition: transform 0.5s;
      }
    `}
`

export const CourseLevel = styled.div`
  padding: 5px;
  border-radius: 4px;
  position: absolute;
  background-color: rgb(7, 210, 7);
  color: var(--color-background);
  font-size: 0.6rem;
  font-weight: 700;
  top: -10px;
  right: -10px;
  z-index: 1000;

  ${media.lessThan("medium")`
     font-size: 0.8rem;
 `}
`

export const CourseImage = styled.div`
  width: 100%;
  padding: 0;

  & img {
    width: 100%;
    border-radius: 4px;
  }
`

export const CourseTitle = styled.h2`
    font-size: 0.9rem;
    line-height: 1.1rem;
    font-weight: bold;
    color: var(--color-text);
    margin: 10px 0;    
    letter-spacing: 0px;

    ${media.lessThan("medium")`
        font-size: 1rem;
    `}
`

export const CourseGroupInfo = styled.div`
  text-align: left;
  display: grid;
  justify-content: space-between;
`

export const CourseGroupDescription = styled.p`
  font-size: 0.7rem;
  line-height: 1rem;
  font-weight: 400;
  color: var(--color-text-light);
  margin: 5px 0;

  ${media.lessThan("medium")`
    font-size: 0.8rem;
  `}
`

export const CourseGroupSubTitle = styled.h3`
  font-size: 0.7rem;
  line-height: 1rem;
  font-weight: bold;
  color: var(--color-text);
  margin: 5px 0;

  ${media.lessThan("medium")`
    font-size: 0.8rem;
  ss`}
`

export const CourseGroupSubDescription = styled.p`
  font-size: 0.7rem;
  line-height: 1rem;
  font-weight: 400;
  color: var(--color-text-light);
  margin: 5px 0;
  display: flex;
  align-items: center;

  & strong {
    padding: 0 0 0 2px;
    font-size: 1.2rem;
    color: rgb(7, 210, 7);
  }

  ${media.lessThan("medium")`
    font-size: 0.8rem;
  `}

  & img {
    width: 0.8rem;
    margin-right: 2px;
  }
`

export const CourseGroupDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CourseGroupMore = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  border-radius: 4px;
  left: 15px;
  right: 15px;
  position: absolute;
  bottom: 15px;
  padding: 0 10px;
  line-height: 1.3em;
  font-weight: 500;
  color: var(--color-text);
  background: var(--color-primary-light);
  height: 46px;

  ${media.lessThan("medium")`
    height: 56px;
  `}
`

