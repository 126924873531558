import React from "react";
import Img from "gatsby-image";

import * as S from './styled';

const CourseCard = ({ title, slug, image, introduction, author, level, class_course }) => {
  return (
    <S.CourseCardWrapper
        to={`/${slug}`}
        rel="noopener noreferrer"
        className="course-container"
        key={title}
    >
        <S.CourseLevel style={{ display: level ? "block" : "none" }}>
          {level}
        </S.CourseLevel>
        <S.CourseImage>
          <Img fluid={image} />
        </S.CourseImage>
        <S.CourseTitle>{title}</S.CourseTitle>
        <S.CourseGroupInfo>
          <S.CourseGroupDescription>{introduction}</S.CourseGroupDescription>
          <S.CourseGroupDetails>
            <div>
              <S.CourseGroupSubTitle>Qtd de aulas</S.CourseGroupSubTitle>
              <S.CourseGroupSubDescription>{class_course}</S.CourseGroupSubDescription>
            </div>
            <div>
              <S.CourseGroupSubTitle>Autor</S.CourseGroupSubTitle>
              <S.CourseGroupSubDescription>{author}</S.CourseGroupSubDescription>
            </div>
          </S.CourseGroupDetails>
          <S.CourseGroupMore>Saiba mais</S.CourseGroupMore>
        </S.CourseGroupInfo>
    </S.CourseCardWrapper>
  );
};

export default CourseCard;